import * as React from "react"

import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import LoadingSpinner from "@/components/common/LoadingSpinner"
import Icon from "@/components/Icon"
import { type ICONS } from "@/components/Icon/constants"
import { cn } from "@/utils/cn"

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-primary text-accent shadow-sm hover:bg-primary/90",
        destructive:
          "bg-destructive text-white shadow-sm hover:bg-destructive/80",
        outline:
          "border border-border text-primary bg-background shadow-sm hover:bg-secondary",

        secondary: "bg-secondary text-primary shadow-sm hover:bg-secondary/80",
        ghost: "hover:bg-secondary text-accent",
        link: "text-primary underline-offset-4 hover:underline",
        reload: "text-primary bg-background",
      },
      size: {
        default: "h-9 px-4 py-2",
        sm: "h-8 rounded-md px-3 text-xs",
        lg: "h-10 rounded-md px-8",
        icon: "h-9 w-9",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  isLoading?: boolean
  icon?: keyof typeof ICONS
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      isLoading = false,
      icon,
      children,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(
          buttonVariants({ variant, size, className }),
          isLoading && " bg-primary text-accent",
        )}
        ref={ref}
        disabled={isLoading || props.disabled}
        {...props}
      >
        {isLoading ? (
          <LoadingSpinner
            className={cn(
              "absolute left-1/2 mt-0 size-5 -translate-x-1/2 stroke-accent transition-opacity duration-200",
            )}
          />
        ) : (
          <>
            {icon && (
              <Icon
                type={icon}
                size="xs"
                className={cn(
                  size !== "icon" && "mr-2",
                  variant === "default" && "text-accent",
                  variant === "destructive" && "text-white",
                  icon === "loader" && "animate-spin",
                  className,
                  "border-none",
                )}
              />
            )}
            {children}
          </>
        )}
      </Comp>
    )
  },
)
Button.displayName = "Button"

export { Button, buttonVariants }
