import { type FC } from "react"

import { cn } from "@/utils/cn"

import { ICONS } from "./constants"
import { type IconProps } from "./types"

const Icon: FC<IconProps> = ({ type, size = "sm", className, color }) => {
  const IconElement = ICONS[type]

  return (
    <IconElement
      className={cn(
        color ? `text-${color}` : "text-primary",
        className,
        size === "xs" && "size-4",
        size === "sm" && "size-6",
        size === "md" && "size-[42px]",
        size === "lg" && "size-7",
        size === "dot" && "size-[5.07px]",
      )}
    />
  )
}

export default Icon
